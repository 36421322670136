// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
// const { getStorage, connectStorageEmulator } = require("firebase/storage");
import { getStorage, connectStorageEmulator } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const hostname = window.location.hostname;
console.log("hostname: ", hostname);
const app =
  hostname === "localhost" || hostname === "127.0.0.1"
    ? initializeApp({
        apiKey: "demo-key",
        authDomain: "demo-domain",
        projectId: "demo-project",
        storageBucket: "demo-project.appspot.com",
        appId: "demo-appId",
        messagingSenderId: "demo-id",
        measurementId: "demo-measurementId",
        // apiKey: process.env.REACT_APP_APIKEY,
        // authDomain: process.env.REACT_APP_AUTHDOMAIN,
        // projectId: process.env.REACT_APP_PROJECTID,
        // storageBucket: process.env.REACT_APP_DEMOSTORAGEBUCKET,
        // messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        // appId: process.env.REACT_APP_APPID,
        // measurementId: process.env.REACT_APP_MEASUREMENTID,
      })
    : initializeApp({
        apiKey: "AIzaSyAtiPOOphYvV--LHj-gQceIIicPgWjYOiQ",
        authDomain: "brathula-v1.firebaseapp.com",
        projectId: "brathula-v1",
        storageBucket: "brathula-v1.appspot.com",
        messagingSenderId: "205844801298",
        appId: "1:205844801298:web:7443d60b72e39d569cf5cb",
        measurementId: "G-CZRSSP5P6W",
      });

// const firebaseConfig = {
//   apiKey: "AIzaSyAtiPOOphYvV--LHj-gQceIIicPgWjYOiQ",
//   authDomain: "brathula-v1.firebaseapp.com",
//   projectId: "brathula-v1",
//   storageBucket: "brathula-v1.appspot.com",
//   messagingSenderId: "205844801298",
//   appId: "1:205844801298:web:7443d60b72e39d569cf5cb",
//   measurementId: "G-CZRSSP5P6W",
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

if (hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
