import React from "react";

export default function ShootType({ addData }) {
  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };

  return (
    <>
      <div className="flex items-center m-4">
        <span>Type of Shoot</span>
        <select
          required
          id="shootType"
          name="shootType"
          onChange={onChange}
          className="ml-2 border-orange-400 focus:border-orange-400 "
        >
          <option value="" defaultValue="Please Select" selected disabled>
            Please Select
          </option>
          <option value="photos">Photo Shoot</option>
          <option value="videos">Video Shoot</option>
          <option value="photo and video">Photo and Video shoot</option>
        </select>
      </div>
    </>
  );
}
