import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import OAuth from "../components/OAuth";
// import ResetPassword from "../components/loginComponents/ResetPassword";

export default function Home() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  //handle navigation
  const navigate = useNavigate();

  //users shouldn't have access to this page if they are already logged in
  useEffect(() => {
    // let isSubscribed = true;
    onAuthStateChanged(auth, (data) => {
      if (data) {
        setIsLoggedIn(true);
        console.log(data);
        console.log("Logged In");
      } else {
        console.log("Logged Out");
      }
    });

    return () => {
      // isSubscribed = false;
      console.log("Cleanup");
    };
  }, []);

  const { email, password } = formData;

  const onChange = (e) => {
    // console.log(e.target.value);
    setFormData((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  };

  //Log In an existing user
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    // console.log("Email, password:", email.length, password.length);
    if (!email.endsWith("@brathula.com")) {
      toast.error("You are not allowed on this site");
      return;
    }

    if (email.length === 0 || password.length === 0) {
      toast.info("No username and/or password entered");
      return;
    } else if (!isLoggedIn) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log(user);
          setIsLoggedIn(true);
          console.log(isLoggedIn);
          toast.success("You have logged in");
          navigate(`/menu`);
        })
        .catch((error) => {
          toast.error("The email or the password is incorrect");
          console.log(error.message);
        });
    } else {
      toast.info("You are Already logged in");
      navigate("/");
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <p className="my-5 text-center">You are already logged in</p>
      ) : (
        <>
          <p className="text-center pt-5 text-2xl">Team Log In</p>
          <form className="m-10" onSubmit={onSubmit}>
            <div className="flex flex-col items-center">
              <label htmlFor="email">Email</label>
              <input
                onChange={onChange}
                type="email"
                id="email"
                value={email}
                className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-blue-500"
              />
              <label className="" htmlFor="password">
                Password
              </label>
              <input
                onChange={onChange}
                type="password"
                id="password"
                value={password}
                className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-blue-500"
              />
              {/* <ResetPassword /> */}
            </div>

            <button
              className="mx-auto my-10 px-10 py-2 block shadow-lg text-white uppercase bg-blue-500 hover:bg-orange-500 active:bg-orange-600"
              type="submit"
            >
              Log In
            </button>
          </form>
          {/* <div className="mx-auto w-1/2 flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
            <p className="text-center font-semibold mx-4">OR</p>
          </div> */}
          {/* <OAuth /> */}
        </>
      )}
    </>
  );
}
