import React, { useState, useEffect } from "react";
import { GoPlusCircle } from "react-icons/go";
import { toast } from "react-toastify";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
// import Images from "../Images";

export default function Labels({ image, imageID }) {
  const [aLabels, setALabels] = useState(image.aLabels);
  const [newLabel, setNewLabel] = useState("");

  const url = image.imageURL.replace(
    "https://storage.googleapis.com",
    "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/"
  );

  useEffect(() => {
    console.log("aLabels: ", aLabels);
  }, [aLabels]);

  //   const aLabels = image.aLabels;
  const gLabels = image.labels;

  const onDelete = (e) => {
    const value = e.target.getAttribute("value");

    setALabels(aLabels.filter((item) => item !== value));
  };

  const labelSubmit = (e) => {
    if (!aLabels.includes(newLabel)) {
      setALabels((prevValue) => {
        return [...prevValue, newLabel];
      });

      setNewLabel("");
    }
  };

  const onSubmit = async () => {
    console.log("aLabels: ", aLabels);

    const editRef = doc(db, `images/${imageID}`);

    if (aLabels !== image.aLabels) {
      try {
        await updateDoc(editRef, {
          aLabels: aLabels,
        });
        toast.success(imageID, "has been changed");
      } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
      }
    } else {
      console.log("no data change");
      return;
    }
  };

  return (
    <div className="">
      <div className="h-96">
        <img
          className="w-full h-full object-contain my-10"
          src={url}
          alt={image.imageURL}
        />
      </div>
      <div className="w-4/5 mx-auto mt-2">
        <div className="flex justify-center">
          <label>
            <span className="text-xl">Add Label</span>
            <input
              type="text"
              name="label"
              id="label"
              value={newLabel}
              className="text-center ml-4 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
              onChange={(e) => setNewLabel(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  labelSubmit(e);
                }
              }}
            />
          </label>
          <GoPlusCircle
            onClick={onSubmit}
            className="text-blue-600 text-4xl flex mx-2 cursor-pointer"
          />
        </div>

        {aLabels !== "empty" && (
          <div className="w-full mx-auto m-2">
            {aLabels.map((label, index) => {
              // console.log("label: ", label);
              return (
                <span
                  className="py-1 px-2 bg-orange-400 hover:bg-red-500 m-2 text-xl cursor-pointer"
                  key={index}
                  onClick={onDelete}
                  value={label}
                  title="remove"
                >
                  {label}
                </span>
              );
            })}
          </div>
        )}

        {gLabels && (
          <div className="mt-2 w-11/12">
            {gLabels.map((label, index) => {
              // console.log("label: ", label);
              return (
                <span className="p-1 bg-yellow-400 mx-2 text-xs" key={index}>
                  {label}
                </span>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
