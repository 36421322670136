import React, { useState } from "react";

export default function ShootLocation({ addData }) {
  const [data, setData] = useState("");

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);

    addData(id, value);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Shoot Location:</span>
          <input
            type="text"
            name="name"
            required
            value={data}
            id="shootLocation"
            onChange={onChange}
            maxLength="30"
            className="ml-4 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
          />
        </label>
        {/* <p className="text-xs">30 characters</p> */}
      </div>
    </>
  );
}
