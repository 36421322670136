import React from "react";
import { useNavigate } from "react-router-dom";

export default function Menu() {
  const navigate = useNavigate();

  let menu = [
    { name: "Create Review", link: "/createreview" },
    { name: "Reviews", link: "/reviews" },
    { name: "Estimates", link: "/estimates" },
    { name: "Random Bookings", link: "/bookings" },
    { name: "Booking Enquiries", link: "/bookingenquiries" },
    { name: "Direct Bookings", link: "/directbookings" },
    { name: "Image Labels", link: "/imagelabels" },
    { name: "Add Admin", link: "/adminaccess" },
  ];

  return (
    <>
      <div className="w-full">
        <h1 className="text-4xl text-center m-5">Main Menu</h1>
        <div className="w-1/2 mx-auto">
          <ul className="list-none flex flex-col items-center">
            {menu.map((item) => {
              return (
                <li key={item.name} className="">
                  <button
                    onClick={() => navigate(item.link)}
                    className="shadow-md text-base md:text-2xl p-2 md:p-4 m-2 text-white bg-orange-400 hover:bg-orange-500 active:bg-orange-600 rounded-md"
                  >
                    {item.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
