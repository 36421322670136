import React from "react";

export default function CommentsPrivate({ addData }) {
  const onChange = (e) => {
    const { value, id } = e.target;

    addData(id, value);
  };
  return (
    <div className="m-4">
      <label className="block mb-2">
        <span>Private Comments to Professional</span>
        <textarea
          className="block p-2 text-sm w-4/5 text-gray-900 bg-gray-50 border-orange-400 rounded-lg border focus:border-orange-400"
          id="commentsPrivate"
          rows="4"
          onChange={onChange}
          name="commentsPrivate"
        />
      </label>
    </div>
  );
}
