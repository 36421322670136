import React, { useState, useEffect } from "react";
import { collection, getDocs, query, limit, orderBy } from "firebase/firestore";
import { db } from "../firebase";

export default function Estimates() {
  const [estimates, setEstimates] = useState([]);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "estimates");
      const q = query(colRef, orderBy("submittedAt", "desc"), limit(10));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setEstimates(docs);
      console.log("docs: ", docs);
    })();
  }, []);

  return (
    <>
      <div className="w-full">
        <h1 className="text-2xl text-center mt-5">Estimates</h1>
        <div className="mx-auto w-10/12 m-10">
          {estimates.map((estimate) => {
            const date = new Date(
              estimate.submittedAt.seconds * 1000
            ).toLocaleDateString("en-GB", options);
            const time = new Date(
              estimate.submittedAt.seconds * 1000
            ).toLocaleTimeString("en-US");

            return (
              <div key={estimate.submittedAt} className="border shadow-md p-5">
                {/* {date} */}
                <p>Date: {date}</p>
                <p>Time: {time}</p>
                {/* <p>
                  Professional:{" "}
                  <span className="font-bold">{enquiry.professional}</span>
                </p>
                Question: {enquiry.question} */}
                <hr class="h-px my-2 border-0 bg-gray-700"></hr>
                <div className="grid md:grid-cols-3 my-2">
                  <div>Services: {estimate.services}</div>
                  <div>Duration: {estimate.duration} hour(s)</div>
                  <div>Estimate: {estimate.estimate}</div>
                  {/* <div>Preference: {enquiry.comsPreference}</div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
