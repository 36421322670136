import React, { useState, useEffect } from "react";
import { collection, getDocs, query, limit, orderBy } from "firebase/firestore";
import { db } from "../firebase";

export default function BookingEnquiries() {
  const [enquiries, setEnquiries] = useState([]);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "bookingEnquiries");
      const q = query(colRef, orderBy("submittedAt", "desc"), limit(10));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setEnquiries(docs);
      console.log("docs: ", docs);
    })();
  }, []);

  return (
    <>
      <div className="w-full">
        <h1 className="text-2xl text-center mt-5">Direct Bookings</h1>
        <div className="mx-auto w-10/12 m-10">
          {enquiries.map((enquiry) => {
            const date = new Date(
              enquiry.submittedAt.seconds * 1000
            ).toLocaleDateString("en-GB", options);
            const time = new Date(
              enquiry.submittedAt.seconds * 1000
            ).toLocaleTimeString("en-US");

            return (
              <div key={enquiry.submittedAt} className="border shadow-md p-5">
                {/* {date} */}
                <p>Date: {date}</p>
                <p>Time: {time}</p>
                <p>
                  Professional:{" "}
                  <span className="font-bold">{enquiry.professional}</span>
                </p>
                Question: {enquiry.question}
                <hr class="h-px my-2 border-0 bg-gray-700"></hr>
                <div className="grid md:grid-cols-4 my-2">
                  <div>Name: {enquiry.name}</div>
                  <div>Email: {enquiry.email}</div>
                  <div>Phone: {enquiry.contactNumber}</div>
                  <div>Preference: {enquiry.comsPreference}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

// https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp#todate
