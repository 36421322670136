import React, { useState, useEffect } from "react";
import { collection, getDocs, query, limit, orderBy } from "firebase/firestore";
import { db } from "../firebase";

export default function BookingDirects() {
  const [directBookings, setDirectBookings] = useState([]);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "bookingDirects");
      const q = query(colRef, orderBy("submittedAt", "desc"), limit(10));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setDirectBookings(docs);
      console.log("docs: ", docs);
    })();
  }, []);

  return (
    <>
      <div className="w-full">
        <h1 className="text-2xl text-center mt-5">Direct Bookings</h1>
        <div className="mx-auto w-10/12 my-5">
          {directBookings.map((booking) => {
            const date = new Date(
              booking.submittedAt.seconds * 1000
            ).toLocaleDateString("en-GB", options);
            const time = new Date(
              booking.submittedAt.seconds * 1000
            ).toLocaleTimeString("en-US");

            return (
              <div key={booking.submittedAt} className="border shadow-md p-5">
                {/* {date} */}
                <p>Date: {date}</p>
                <p>Time: {time}</p>
                <p>
                  Professional:{" "}
                  <span class="font-bold">
                    {booking.professional} {booking.user}
                  </span>
                </p>
                <p>Event Details: {booking.eventDetails}</p>
                <p>Event Address: {booking.address}</p>
                <p>Event Date: {booking.date} </p>
                <hr class="h-px my-2 border-0 bg-gray-700"></hr>
                <div className="grid md:grid-cols-3 my-2">
                  <div>Name: {booking.customerName}</div>
                  <div>Email: {booking.email}</div>
                  <div>Phone: {booking.contactNumber}</div>
                </div>
                <div className="grid md:grid-cols-3 my-2">
                  <div>Start Time: {booking.startTime}</div>
                  <div>Duration: {booking.photoDuration}</div>
                  <div>Cost: {booking.photo_cost}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
