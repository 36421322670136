import { useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import ReviewCreate from "./pages/ReviewCreate";
import ReviewEdit from "./pages/ReviewEdit";
import Reviews from "./pages/Reviews";
import Navigation from "./components/Navigation";
import Menu from "./pages/Menu";
import BookingDirects from "./pages/BookingDirects";
import Bookings from "./pages/Bookings";
import BookingEnquiries from "./pages/BookingEnquiries";
import Estimates from "./pages/Estimates";
import ImageLabels from "./pages/ImageLabels";
import AdminAccess from "./pages/AdminAccess";

// import { useNavigate } from "react-router-dom";
//other imports
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { auth } from "./firebase";
// import { onAuthStateChanged } from "firebase/auth";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState();

  const setLoggedInFn = () => {
    setIsLoggedIn(true);
  };

  const setLoggedOutFn = () => {
    setIsLoggedIn(false);
  };

  return (
    <>
      <BrowserRouter>
        <Navigation
          setLoggedInFn={setLoggedInFn}
          setLoggedOutFn={setLoggedOutFn}
        />

        {isLoggedIn === true ? (
          <>
            <Routes>
              <Route path="/menu" element={<Menu />} />
              <Route path="/createreview" element={<ReviewCreate />} />
              <Route path="/reviewedit" element={<ReviewEdit />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/directbookings" element={<BookingDirects />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route path="/bookingenquiries" element={<BookingEnquiries />} />
              <Route path="/estimates" element={<Estimates />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/imagelabels" element={<ImageLabels />} />
              <Route path="/adminaccess" element={<AdminAccess />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route index element={<Home />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
