import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";

import Labels from "./labelComponents/Labels";

export default function Images({ user }) {
  const [images, setImages] = useState([]);
  const [ids, setIDs] = useState([]);

  //   useEffect(() => {
  //     (async () => {
  //       const colRef = collection(db, "images");
  //       const q = query(colRef, where("user", "==", user));
  //       const snapshots = await getDocs(q);
  //       const docs = snapshots.docs.map((doc) => doc.data());
  //       setImages(docs);
  //       //   console.log("docs: ", docs);
  //     })();
  //   }, [user]);

  //   useEffect(() => {
  //     const colRef = collection(db, "images");
  //     const q = query(colRef, where("user", "==", user));

  //     //real time update
  //     onSnapshot(q, (snapshot) => {
  //       snapshot.docs.forEach((doc) => {
  //         setImages((prev) => [...prev, doc.data()]);
  //         setIDs((prev) => [...prev, doc.id]);
  //         // console.log("onsnapshot", doc.data());
  //       });
  //     });
  //   }, [user]);

  useEffect(() => {
    const colRef = collection(db, "images");
    const q = query(colRef, where("user", "==", user));

    onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs;
      setIDs(docs.map((doc) => doc.id));
      setImages(docs.map((doc) => doc.data()));
    });
  }, [user]);

  //   useEffect(() => {
  //     console.log("IDs: ", ids);
  //   }, [ids]);

  useEffect(() => {
    console.log("Images: ", images);
  }, [images]);

  return (
    <>
      {/* <p>Hello World</p> */}
      {/* {images && <p>Information for the network</p>} */}

      {images && (
        <div className="m-4 grid grid-cols-2 grid-flow-row">
          {images.map((image, index) => {
            const imageID = ids[index];
            return <Labels image={image} imageID={imageID} />;
          })}
        </div>
      )}
    </>
  );
}
