import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function AdminAccess() {
  const [email, setEmail] = useState("");

  const functions = getFunctions();
  const addAdmin = httpsCallable(functions, "addAdminRole");

  const onSubmit = async (e) => {
    e.preventDefault();

    addAdmin({ email }).then((result) => {
      console.log(result);
    });

    console.log("Submitted: ", email);
  };

  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-4/5 my-5">
          <form
            className="mx-auto transform translate-x-1/2 translate-y-1/2"
            onSubmit={onSubmit}
          >
            <input
              type="email"
              name="email"
              placeholder="user email"
              required
              id="email"
              className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="my-5 block bg-orange-400 text-white shaddow p-2">
              Make Admin
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
