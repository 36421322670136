//react
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

//firebase
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import ClientName from "../components/reviewCreateComponents/ClientName";
import ShootDate from "../components/reviewCreateComponents/ShootDate";
import ShootLocation from "../components/reviewCreateComponents/ShootLocation";
import ClientProfession from "../components/reviewCreateComponents/ClientProfession";
import ShootTitle from "../components/reviewCreateComponents/ShootTitle";
// import ProID from "../components/reviewCreateComponents/ProID";
// import ProName from "../components/reviewCreateComponents/ProName";
import RatingProduct from "../components/reviewCreateComponents/RatingProduct";
import RatingProfessionalism from "../components/reviewCreateComponents/RatingProfessionalism";
import RatingPunctuality from "../components/reviewCreateComponents/RatingPunctuality";
import RatingOverall from "../components/reviewCreateComponents/RatingOverall";
import CommentsPublic from "../components/reviewCreateComponents/CommentsPublic";
import CommentsPrivate from "../components/reviewCreateComponents/CommentsPrivate";
import ClientCompany from "../components/reviewCreateComponents/ClientCompany";
import ShootType from "../components/reviewCreateComponents/ShootType";
import Professional from "../components/reviewCreateComponents/Professional";

//others
import { toast } from "react-toastify";

export default function ReviewCreate() {
  const [formData, setFormData] = useState();
  // const [isLoading, setIsLoading] = useState();

  const [ratePunctuality, setRatePunctuality] = useState();
  const [rateProfessionalism, setRateProfessionalism] = useState();
  const [rateProduct, setRateProduct] = useState();

  const navigate = useNavigate();

  const addData = useCallback((id, data) => {
    // console.log(id, data);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log("Pressed onSubmit");
    console.log(formData);

    // setIsLoading(true);

    try {
      await addDoc(collection(db, "reviews/"), {
        ...formData,
        createdAt: serverTimestamp(),
        adminPublish: false,
      });
      toast.success("The review has been uploaded.");
      navigate(`/menu`);
      setFormData("");
      // document.getElementById("form").reset();
      // resetForm();
    } catch (error) {
      console.log(error.message);
      toast.error("There was an error uploading review");
    }
  };

  const punctualitySet = (value) => {
    setRatePunctuality(value);
  };

  const professionalismSet = (value) => {
    setRateProfessionalism(value);
  };

  const productSet = (value) => {
    setRateProduct(value);
  };

  return (
    <>
      <div className="w-full pb-5">
        <h1 className="text-center text-2xl mt-5">Create Review</h1>
        <div className="sm:w-2/3 mx-auto">
          <div className="mx-auto p-5 shadow-lg">
            <form className="" onSubmit={onSubmit} id="form">
              <Professional addData={addData} />
              {/* <ProName addData={addData} formData={formData} /> */}
              {/* <ProID addData={addData} /> */}
              <ClientName addData={addData} />
              <ClientProfession addData={addData} />
              <ClientCompany addData={addData} />
              <ShootTitle addData={addData} />
              <ShootDate addData={addData} />
              <ShootLocation addData={addData} />
              <ShootType addData={addData} />
              <RatingPunctuality
                addData={addData}
                punctualitySet={punctualitySet}
              />
              <RatingProfessionalism
                addData={addData}
                professionalismSet={professionalismSet}
              />
              <RatingProduct addData={addData} productSet={productSet} />
              <RatingOverall
                addData={addData}
                ratePunctuality={ratePunctuality}
                rateProfessionalism={rateProfessionalism}
                rateProduct={rateProduct}
              />
              <CommentsPublic addData={addData} />
              <CommentsPrivate addData={addData} />
              <button className="text-white p-2 m-2 shadow bg-orange-500 hover:bg-orange-600 cursor-pointer">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
