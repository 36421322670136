import React, { useState } from "react";

export default function RatingProfessionalism({ addData, professionalismSet }) {
  const [data, setData] = useState("");

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);

    addData(id, Math.round(value * 100) / 100);
    professionalismSet(Math.round(value * 100) / 100);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Professionalism Rating:</span>
          <input
            type="number"
            name="ratingProfessionalism"
            step="0.5"
            max={5}
            min={0}
            required
            value={data}
            id="ratingProfessionalism"
            onChange={onChange}
            maxLength="10"
            className="ml-4 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
          />
        </label>
        {/* <p className="text-xs">30 characters</p> */}
      </div>
    </>
  );
}
