import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

import Images from "../components/imageLabelComponents/Images";

export default function ImageLabels() {
  const [profiles, setProfiles] = useState([]);
  const [user, setUser] = useState();
  // const [ids, setIDs] = useState();

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "profiles");
      const q = query(colRef, where("userPublish", "==", "true"));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      // const docsID = snapshots.docs.map((doc) => doc.id);

      setProfiles(docs);
      // setIDs(docsID);
      // console.log("docs: ", docs);
      // console.log("ID: ", docsID);
    })();
  }, []);

  const onChange = (e) => {
    const { value } = e.target;

    console.log("Value:", value);
    setUser(value);
  };

  return (
    <>
      <div className="flex items-center m-4">
        <span>Professional</span>
        <select
          required
          id="professional"
          name="professional"
          onChange={onChange}
          className="ml-2 border-orange-400 focus:border-orange-400 "
        >
          <option value="" defaultValue="Please Select" selected disabled>
            Please Select
          </option>
          {profiles.map((profile) => {
            return (
              <option key={profile.user} value={profile.user}>
                {profile.name}
              </option>
            );
          })}

          {/* <option value="edited_images">Edited Images</option>
          <option value="raw_images">Raw Images</option>
          <option value="raw_and_edited">Both Raw and Edited</option> */}
        </select>
      </div>
      {user && <Images user={user} />}
    </>
  );
}
