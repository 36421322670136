import { useEffect, useState } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

export default function Professional({ addData }) {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "profiles");
      const q = query(colRef, where("userPublish", "==", "true"));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setProfiles(docs);
      // console.log("docs: ", docs);
    })();
  }, []);

  const onChange = (e) => {
    // console.log(e.target.value.name);
    const { value } = e.target;
    // const { name, user } = value;

    console.log(value);
    // console.log(value.indexOf(","));
    const separator = value.indexOf(",");
    console.log(separator);
    const end = value.length;

    const name = value.substring(0, separator);
    const proID = value.substring(separator + 1, end);

    addData("proName", name);
    addData("proID", proID);
  };

  return (
    <>
      <div className="flex items-center m-4">
        <span>Professional</span>
        <select
          required
          id="professional"
          name="professional"
          onChange={onChange}
          className="ml-2 border-orange-400 focus:border-orange-400 "
        >
          <option value="" defaultValue="Please Select" selected disabled>
            Please Select
          </option>
          {profiles.map((profile) => {
            return (
              <option key={profile.user} value={[profile.name, profile.user]}>
                {profile.name}
              </option>
            );
          })}
          {/* <option value="edited_images">Edited Images</option>
          <option value="raw_images">Raw Images</option>
          <option value="raw_and_edited">Both Raw and Edited</option> */}
        </select>
      </div>
    </>
  );
}
