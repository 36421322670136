import React, { useEffect, useRef } from "react";

export default function RatingOverall({
  addData,
  ratePunctuality,
  rateProfessionalism,
  rateProduct,
}) {
  let overAll = useRef(0);

  useEffect(() => {
    if (rateProduct && rateProfessionalism && ratePunctuality) {
      overAll.current =
        (rateProduct * 1 + rateProfessionalism * 1 + ratePunctuality * 1) / 3;
      addData("ratingOverall", Math.round(overAll.current * 100) / 100);
    }
  }, [rateProduct, rateProfessionalism, ratePunctuality, addData]);

  return (
    <>
      {overAll.current > 0 && (
        <div className="ml-4">
          Overall Rating: {Math.round(overAll.current * 100) / 100}
        </div>
      )}
    </>
  );
}
